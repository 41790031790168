<template>
  <div id="emptyLayout" class="asf-empty-page">
    <slot />
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'EmptyLayout'
})
</script>
